import $ from 'jquery'
import * as _ from 'lodash'
import { Buffer } from "buffer"
import 'core-js'
import '@fortawesome/fontawesome-free/js/all.js'

window['jQuery'] = $
window['$'] = $ // fix captcha change image bug
window['lodash'] = _

globalThis.Buffer = Buffer

import '../version'

import '../bootstrap-v5-ext'
import '../assets/stylesheets'

import "../store/main"