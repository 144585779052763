import { useStorePageStore } from "@/store/store/page"
import { type App } from "vue"
import { useStoreSessionStore } from "../store/session"

export default function(app: App) {
  const router = app.config.globalProperties.$router

  router.beforeEach(async (to: any, from: any, next: any) => {
    const session = useStoreSessionStore(app.config.globalProperties.$pinia)
    await session.prepare(app.config.globalProperties)

    if (to.meta.auth !== false && session.account == null) {
      next("/login")
    } else {
      next()
    }
  })

  router.beforeEach(async (to: any, from: any) => {
    const page = useStorePageStore(app.config.globalProperties.$pinia)
    page.clear()
  })
}
