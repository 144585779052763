import type { BaseRequest } from '@/lib/requests'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useStorePageStore = defineStore('storePage', () => {
  const caches = new Map<any, any>()
  const page_id = ref(new Date().getTime())

  function request<T extends BaseRequest<any>>(klass: new () => T): T {
    if (caches.has(klass)) {
      console.log('page cached')
      return caches.get(klass)
    } else {
      const request = new klass()
      caches.set(klass, request)
      return request
    }
  }

  function clear() {
    caches.clear()
    page_id.value = new Date().getTime()
  }

  return { request, clear, page_id }
})
